import {useState} from 'react'
import logo from '../assets/logo512.png'
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import {FaBars} from "react-icons/fa"
import {FaRegWindowClose} from "react-icons/fa"
import {GrClose} from "react-icons/gr"


export default function Navbar() {

  const [menu, setMenu] = useState('initial');
  const navigate = useNavigate();

  function menuDisplayStatus(){
    if(menu == 'initial' || menu == "close"){
      setMenu('open');
      console.log('open')
    } else {
      setMenu('close');
      console.log('close')
    }
  }

  return (
    <>
      <div className="mynav">

        <div className="mynav-logo-container" onClick={() => navigate('/')}>
          <img src={logo} className="mynav-logo"></img>
          <div className="mynav-logo-name">Mad Dragon Foundation</div>
        </div>

        <div className="mynav-links-container">
          <div className="mynav-link" onClick={() => navigate('/')}>Home</div>
          <div className="mynav-link" onClick={() => navigate('/about')}>About</div>
          <div className="mynav-link" onClick={() => navigate('/programs')}>Programs</div>
          <div className="mynav-link" onClick={() => navigate('/events')}>Events</div>
          <div className="mynav-link me-5" onClick={() => navigate('/contact')}>Contact</div>
          <div className="mynav-link donate-link" onClick={() => navigate('/donate')}>Donate</div>
        </div>

        

        <div className="mynav-hamburger">

          {menu == 'initial' && <FaBars className="hamburger-icon" onClick={menuDisplayStatus} />}

          {menu == 'close' && <FaBars className="hamburger-icon" onClick={menuDisplayStatus} />}

          {menu == 'open' && <GrClose className="hamburger-icon" onClick={menuDisplayStatus} />}

        </div>

      </div>

      {menu == 'open' && (
        <div className="vertical-menu">
          <div className="mynav-link" onClick={() => { navigate('/'); menuDisplayStatus() }}>Home</div>
          <div className="mynav-link" onClick={() => { navigate('/about'); menuDisplayStatus() }}>About</div>
          <div className="mynav-link" onClick={() => { navigate('/programs'); menuDisplayStatus() }}>Programs</div>
          <div className="mynav-link" onClick={() => { navigate('/events'); menuDisplayStatus() }}>Events</div>
          <div className="mynav-link" onClick={() => { navigate('/contact'); menuDisplayStatus() }}>Contact</div>
          <div className="mynav-link donate-link" onClick={() => { navigate('/donate'); menuDisplayStatus() }}>Donate</div>
        </div>
      )}

      {menu == 'close' && (
        <div className="vertical-menu-closed">
          <div className="mynav-link" onClick={() => navigate('/')}>Home</div>
          <div className="mynav-link" onClick={() => navigate('/about')}>About</div>
          <div className="mynav-link" onClick={() => navigate('/programs')}>Programs</div>
          <div className="mynav-link" onClick={() => navigate('/donate')}>Donate</div>
          <div className="mynav-link" onClick={() => navigate('/getInvolved')}>Get&nbsp;Involved</div>
          <div className="mynav-link" onClick={() => navigate('/contact')}>Contact</div>
        </div>
      )}
    </>

  )


}


/*
          
      
          {menu == 'open' && (
              <div onClick={menuDisplayStatus}>
                <Hamburger hwidth="3rem"  />
              </div>
          )}    


          
          {menu == 'initial' && <div><Hamburger hwidth="3rem" onClick={menuDisplayStatus} /></div>}


<nav className="navbar navbar-expand-lg  py-1 mad-nav">
      <div className="container-fluid">
        <span className="navbar-brand cursor-pointer yeon" onClick={() => navigate('/')} >
          <img src={logo} width="50" height="50" className="d-inline-block align-text-center" alt="Mad Dragon Foundation" />
          <span>&nbsp;&nbsp;Mad Dragon Foundation</span>
        </span>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#" onClick={() => navigate('/')}>Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#" onClick={() => navigate('/about')}>About</a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href="#" onClick={() => navigate('/donate')}>Donate</a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href="#" onClick={() => navigate('/getInvolved')}>Get Involved</a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" href="#" onClick={() => navigate('/contact')}>Contact</a>
            </li>
            <li className="nav-item dropdown point">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Programs
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">About the Clubs of America Program</a></li>
                <li><a className="dropdown-item" href="#">Mad Dragon Clubs of America</a></li>
                <li><a className="dropdown-item" href="#">Mad Dragon Festivals of America</a></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
*/


/*
  return (
    <div className="mynav">

      <div className="mynav-logo-container">
        <img src={logo} className="mynav-logo"></img>
        <div className="mynav-logo-name">Mad Dragon Foundation</div>
      </div>

      <div className="mynav-links-container">
        <div className="mynav-link" onClick={() => navigate('/')}>Home</div>
        <div className="mynav-link" onClick={() => navigate('/about')}>About</div>
        <div className="mynav-link" onClick={() => navigate('/programs')}>Programs</div>
        <div className="mynav-link" onClick={() => navigate('/donate')}>Donate</div>
        <div className="mynav-link" onClick={() => navigate('/getInvolved')}>Get&nbsp;Involved</div>
        <div className="mynav-link" onClick={() => navigate('/contact')}>Contact</div>
      </div>

      <div className="mynav-hamburger">
        <FaBars className="hamburger-icon" onClick={menuDisplayStatus}/>
      </div>

      {menu=='open' && (
        <div className="vertical-menu">
          <div className="mynav-link" onClick={() => {navigate('/'); menuDisplayStatus()}}>Home</div>
          <div className="mynav-link" onClick={() => {navigate('/about'); menuDisplayStatus()}}>About</div>
          <div className="mynav-link" onClick={() => {navigate('/programs'); menuDisplayStatus()}}>Programs</div>
          <div className="mynav-link" onClick={() => {navigate('/donate'); menuDisplayStatus()}}>Donate</div>
          <div className="mynav-link" onClick={() => {navigate('/getInvolved'); menuDisplayStatus()}}>Get&nbsp;Involved</div>
          <div className="mynav-link" onClick={() => {navigate('/contact'); menuDisplayStatus()}}>Contact</div>
        </div>
      )}

      {menu=='close' && (
        <div className="vertical-menu-closed">
          <div className="mynav-link" onClick={() => navigate('/')}>Home</div>
          <div className="mynav-link" onClick={() => navigate('/about')}>About</div>
          <div className="mynav-link" onClick={() => navigate('/programs')}>Programs</div>
          <div className="mynav-link" onClick={() => navigate('/donate')}>Donate</div>
          <div className="mynav-link" onClick={() => navigate('/getInvolved')}>Get&nbsp;Involved</div>
          <div className="mynav-link" onClick={() => navigate('/contact')}>Contact</div>
        </div>
      )}
    
    </div>





  )
*/