import React, {Suspense} from 'react'
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"

import Navbar from './components/Navbar';
const About = React.lazy(()=>import('./pages/about/About'));
const Contact = React.lazy(()=>import('./pages/contact/Contact'));
const Donate = React.lazy(()=>import('./pages/donate/Donate'));
const Events = React.lazy(()=>import('./pages/events/Events'));
const Home = React.lazy(()=>import('./pages/home/Home'));
const Programs = React.lazy(()=>import('./pages/programs/Programs'));
const PrivacyPolicy = React.lazy(()=>import('./pages/privacyPolicy/PrivacyPolicy'));
const Thankyou = React.lazy(()=>import('./pages/thankyou/Thankyou'));
//Note: for lazy loading to work the Routes needs to be wrapped in a Suspense element.


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Suspense >
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/events" element={<Events />} />
            <Route path="/" element={<Home />} />
            <Route path="/programs" element={<Programs />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/thankyou' element={<Thankyou />} />
            <Route path="/*" element={<Home />} />
            
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
